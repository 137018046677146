import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classnames from "classnames";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowSize } from "react-use";

/* Import Global Hooks */
import useCart from "@hooks/useCart";

/* Import Global Styles */
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

/* Import Local Styles */
import "./swiper.scss";
import * as styles from "./product.module.scss";

/* Import Local Assets */
import RightArrowSvgSrc from "./assets/right-arrow.svg";

const Product = ({ pageContext: { product } }) => {
  const [availableForSale, setAvailableForSale] = useState(
    product.variants[0].availableForSale
  );

  const breakpoint = 992;
  const { width } = useWindowSize();
  const { addVariantToCart } = useCart();

  const handleAddToCart = (event) => {
    event.preventDefault();
    // Get Form
    const formElement = event.target.closest("form");
    if (formElement) {
      const formData = new FormData(formElement);
      if (formData) {
        const variant = formData.get("variant");
        if (variant) {
          addVariantToCart(product, variant, 1);
        }
      }
    }
  };

  const handleChange = () => {
    const formElement = event.target.closest("form");
    if (formElement) {
      const formData = new FormData(formElement);
      if (formData) {
        const shopifyId = formData.get("variant");
        const variantObject = product.variants.find((variant) => {
          return variant.shopifyId === shopifyId;
        });
        setAvailableForSale(
          variantObject ? variantObject.availableForSale : false
        );
      }
    }
  };

  return (
    <form
      className={classnames("product", styles.product)}
      onChange={handleChange}
    >
      {/* Product Images */}
      {width <= breakpoint ? (
        <Swiper
          className={classnames("images", styles.images)}
          navigation={true}
          modules={[Navigation, Pagination]}
          pagination={{
            clickable: true,
          }}
          slidesPerView={1}
          spaceBetween={10}
        >
          {product.media.map((media) => (
            <SwiperSlide className={classnames("image")} key={media.id}>
              <GatsbyImage
                image={getImage(media.image.localFile)}
                alt={media.image.altText}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className={classnames("images", styles.images)}>
          {product.media.map((media) => (
            <GatsbyImage
              image={getImage(media.image.localFile)}
              alt={media.image.altText}
              className={classnames("image")}
              key={media.id}
            />
          ))}
        </div>
      )}
      {/* Product Information */}
      <div className={classnames("information", styles.information)}>
        {/* Title */}
        <h1 className={classnames("title", styles.title)}>{product.title}</h1>
        {/* Description */}
        <div
          dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
          className={classnames("description", styles.description)}
        />
        {/* Product Options */}
        <ul className={classnames("options", styles.options)}>
          {product.variants.map((variant, index) => {
            return (
              <li key={variant.shopifyId}>
                {index === 0 ? (
                  <input
                    type="radio"
                    name="variant"
                    value={variant.shopifyId}
                    id={variant.shopifyId}
                    defaultChecked
                  />
                ) : (
                  <input
                    type="radio"
                    name="variant"
                    value={variant.shopifyId}
                    id={variant.shopifyId}
                  />
                )}
                <label htmlFor={variant.shopifyId}>
                  {variant.selectedOptions[0].value}
                </label>
              </li>
            );
          })}
        </ul>
        {/* Price */}
        <p className="price">${product.priceRangeV2.maxVariantPrice.amount}</p>
        {/* Add to Cart Button */}
        <button
          onClick={handleAddToCart}
          type="submit"
          className={classnames("add-to-cart", styles.addToCart)}
          disabled={!availableForSale}
        >
          {availableForSale ? "Add to Cart" : "Sold Out"}
        </button>
      </div>
    </form>
  );
};

export default Product;
